var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-card-header',{attrs:{"header-class":"pt-0"}},[_c('h4',[_vm._v("تفاصيل قسم - "+_vm._s(_vm.Detailschapter.name))]),_c('b-badge',{staticClass:"badge",attrs:{"variant":_vm.Detailschapter.isFree ? 'danger' : 'primary'}},[_vm._v(_vm._s(_vm.Detailschapter.isFree ? "مدفوع" : "مجاني"))])],1),_c('b-card-body',[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('ek-input-text',{attrs:{"name":"name","readonly":"","label":" اسم الجامعة ","placeholder":"ادخل اسم الجامعة"},model:{value:(_vm.Detailschapter.universityName),callback:function ($$v) {_vm.$set(_vm.Detailschapter, "universityName", $$v)},expression:"Detailschapter.universityName"}}),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('ek-input-text',{attrs:{"readonly":"","options":_vm.years,"placeholder":"السنة","name":" اختر السنة","label":"السنة","rules":[
                                        {
                                            type: 'required',
                                            message: 'السنة مطلوبة',
                                        } ]},model:{value:(_vm.Detailschapter.yearName),callback:function ($$v) {_vm.$set(_vm.Detailschapter, "yearName", $$v)},expression:"Detailschapter.yearName"}})],1),_c('b-col',{staticClass:"pl-2",attrs:{"lg":"6","md":"12"}},[_c('ek-input-text',{attrs:{"readonly":"","options":_vm.semesters,"placeholder":"اختر الفصل","name":" اختر الفصل","label":"الفصل","rules":[
                                        {
                                            type: 'required',
                                            message: 'الفصل مطلوب',
                                        } ]},model:{value:(_vm.Detailschapter.semesterName),callback:function ($$v) {_vm.$set(_vm.Detailschapter, "semesterName", $$v)},expression:"Detailschapter.semesterName"}})],1)],1),_c('b-row',[_c('ek-input-text',{attrs:{"readonly":"","type":"number","name":"name","label":" عدد الدروس ","placeholder":"عدد الدروس"},model:{value:(_vm.Detailschapter.lessonCount),callback:function ($$v) {_vm.$set(_vm.Detailschapter, "lessonCount", $$v)},expression:"Detailschapter.lessonCount"}}),_c('ek-input-text',{ref:"order",attrs:{"name":"  ترتيب القسم","label":"ترتيب القسم","type":"number","placeholder":" ادخل الترتيب"},model:{value:(_vm.Detailschapter.order),callback:function ($$v) {_vm.$set(_vm.Detailschapter, "order", $$v)},expression:"Detailschapter.order"}})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('ek-input-text',{attrs:{"readonly":"","rules":[
                                {
                                    type: 'required',
                                    message: '  يرجى اختيار الكلية',
                                } ],"name":"classes","placeholder":"تابع الكلية","label":"الكلية","clearable":""},model:{value:(_vm.Detailschapter.facultyName),callback:function ($$v) {_vm.$set(_vm.Detailschapter, "facultyName", $$v)},expression:"Detailschapter.facultyName"}}),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"pl-2",attrs:{"lg":"4","md":"12"}},[_c('ek-input-text',{attrs:{"placeholder":"اسم المادة","name":" اسم المادة","label":"اسم المادة","rules":[
                                        {
                                            type: 'required',
                                            message: 'اسم المادة مطلوب',
                                        } ]},model:{value:(_vm.Detailschapter.subjectName),callback:function ($$v) {_vm.$set(_vm.Detailschapter, "subjectName", $$v)},expression:"Detailschapter.subjectName"}})],1),_c('b-col',{staticClass:"pl-2",attrs:{"lg":"4","md":"12"}},[_c('ek-input-select',{attrs:{"rules":[
                                        {
                                            type: 'required',
                                            message: '  استاذ القسم',
                                        } ],"options":_vm.teachersNane,"name":"classes","placeholder":" ادخل استاذ القسم ","label":"استاذ القسم","clearable":""},model:{value:(_vm.Detailschapter.teacherId),callback:function ($$v) {_vm.$set(_vm.Detailschapter, "teacherId", $$v)},expression:"Detailschapter.teacherId"}})],1),_c('b-col',{staticClass:"pl-2",attrs:{"lg":"4","md":"12"}},[_c('ek-input-select',{attrs:{"options":_vm.unitNames,"placeholder":"اسم الوحدة","name":" اسم الوحدة","label":" اسم الوحدة","rules":[
                                        {
                                            type: 'required',
                                            message: 'اسم الوحدة مطلوب',
                                        } ]},model:{value:(_vm.Detailschapter.unitId),callback:function ($$v) {_vm.$set(_vm.Detailschapter, "unitId", $$v)},expression:"Detailschapter.unitId"}})],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"flex justify-content-between"},[_c('AddLessons',{staticClass:"ml-2"}),_c('b-tabs',{staticClass:"border-2",attrs:{"pills":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('b-tab',{attrs:{"title":"الفيديوهات","active":""}}),_c('b-tab',{attrs:{"title":"المستندات"}})],1)],1),_c('ek-table',{attrs:{"columns":_vm.tab == 0 ? _vm.VedioColumns : _vm.DucColumns,"items":_vm.Detailschapter.lessons,"no_select":true,"no_delete":true},scopedSlots:_vm._u([{key:"table-header",fn:function(){return [_c('div',{staticClass:"d-flex p-1"},[_c('unicon',{staticClass:"pr-1",attrs:{"fill":"gray","name":"bookmark"}}),_c('h4',[_vm._v("دروس القسم")])],1)]},proxy:true},{key:"items.dateCreated",fn:function(ref){
                                    var value = ref.value;
return [_vm._v(" "+_vm._s(value ? new Date(value).toLocaleDateString() : "_")+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }